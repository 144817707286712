import GamPosition from './gam_position'
import { debounce } from '../../../../lib/utils/timing'
import { dispatchCustomEvent } from '../../../../lib/utils/events'

const DEFAULT_GAM_CONFIG = {
  setting: {},
  event: {},
  positions: {}
}
const GAM_CONFIG_NAMESPACE = '__GAM_CONFIG'
const DIDOMI_WAIT = '__DIDOMI_WAIT'
const DIDOMI_CONSENT_EVENT = 'cjsUserConsented'

export default (() => {
  class GAM {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      this.stackGroups = new Map()
      this.renderedGroups = new Set()
      this.positions = []
      this.waitForDidomiEvent = window[DIDOMI_WAIT] ?? false
      this._init()
    }

    // Public Methods
    createGamPlacement(elem, instance) {
      const placement = GamPosition.fromElement(elem, instance)
      if (!placement) return console.error('[GAM] error creating placement')
      this.positions.push(placement)
      placement.stackGroup && this._addStackGroupPosition(placement)
      return placement
    }

    destroyGAMPlacement(elems) {
      if (Array.isArray(elems) && window?.benji?.destroy) {
        window.benji?.destroy(elems)
      }
    }

    // Private Methods
    _addStackGroupPosition(position) {
      if (position.stackGroup) {
        if (!this.stackGroups.has(position.stackGroup)) {
          this.stackGroups.set(position.stackGroup, [])
        }
        this.stackGroups.get(position.stackGroup).push(position)
      }
    }

    _initializePositions() {
      this.elems.forEach(elem => {
        const gamPosition = GamPosition.fromElement(elem)
        if (gamPosition) {
          this.positions.push(gamPosition)
          gamPosition.stackGroup && this._addStackGroupPosition(gamPosition)
        }
      })
    }

    _init() {
      this._initializePositions()
      const globalConfig = this._getGlobalGamConfig()
      this.config = {
        ...globalConfig,
        positions: {
          ...(globalConfig.positions || {}),
          ...this._getFormattedIndexPositionConfig()
        }
      }

      if (this.waitForDidomiEvent) {
        document.addEventListener(DIDOMI_CONSENT_EVENT, e => {
          const { consentData } = e.detail
          // check if benji is already mounted on window.
          if (window.benji) {
            // if cjsUserConsented event happens more than once this will ignore them.
            if (!window.benji.isReady) {
              this._mergeDidomiConsentData(consentData)
              this._startBenji()
            }
          } else {
            window.addEventListener('benji:mounted', () => {
              this._mergeDidomiConsentData(consentData)
              this._startBenji()
            })
          }
        })
      } else {
        this._startBenji()
      }
    }

    _mergeDidomiConsentData(consentData) {
      const { region, usercountry, gdpr } = this.config.setting.i13n || {}
      const context = JSON.stringify({ region, usercountry, gdpr })
      const {
        allowFirstPartyAds,
        allowOnlyLimitedAds,
        allowOnlyNonPersonalizedAds
      } = window.benji.getConsent(context, consentData)
      this.config.setting.consent = {
        ...this.config.setting.consent,
        allowFirstPartyAds,
        allowOnlyLimitedAds,
        allowOnlyNonPersonalizedAds
      }
    }

    _startBenji() {
      if (window.benji) {
        window.benji.start(this.config)
      } else {
        window.benji = { config: this.config }
      }
      this._addEventListeners()
      this._addPublicInterface()
      dispatchCustomEvent('GAM:inited', {})
    }

    _getGlobalGamConfig() {
      return { ...DEFAULT_GAM_CONFIG, ...(window[GAM_CONFIG_NAMESPACE] || DEFAULT_GAM_CONFIG) }
    }

    _getFormattedIndexPositionConfig() {
      return this.positions.reduce((acc, position) => {
        if (position.region !== 'index') {
          return acc
        }
        if (position.hasRoomToRender()) {
          acc[position.id] = position.config
          position.setCreatedStatus()
          if (position.stackGroup) {
            this.renderedGroups.add(position.stackGroup)
          }
        } else {
          position.destroyPlaceholder()
        }
        return acc
      }, {})
    }

    _handleWindowResize() {
      this.positions.forEach(position => {
        position.handleWindowResize()
      })
    }

    _handleStackGroupScroll(position) {
      if (this.renderedGroups.has(position.stackGroup)) return
      const stackGroup = this.stackGroups.get(position.stackGroup)
      const shouldRender = stackGroup.some(pos => pos.handleWindowScroll(true))
      if (!shouldRender) return

      const config = stackGroup.reduce((acc, pos) => {
        acc[pos.id] = pos.config
        pos.setCreatedStatus()
        return acc
      }, {})

      this.renderedGroups.add(position.stackGroup)
      window.benji?.render?.(config)
    }

    _handleWindowScroll() {
      this.positions.forEach(position => {
        if (position.stackGroup) {
          this._handleStackGroupScroll(position)
        } else {
          position.handleWindowScroll()
        }
      })
    }

    _handleAdBlockEnabled() {
      // TODO: uncomment when Benji supports updating abk value
      // window.benji?.updateI13N?.({ abk })
      // window.benji?.refresh?.()
    }

    _addEventListeners() {
      document.addEventListener('GAM:refresh', ({ detail: adsToRefresh = [] }) => {
        window.benji?.refresh(adsToRefresh)
      })
      document.addEventListener('GAM:enableAdBlock', this._handleAdBlockEnabled.bind(this))

      window.addEventListener('resize', debounce(this._handleWindowResize.bind(this), 200))
      window.addEventListener('scroll', debounce(this._handleWindowScroll.bind(this), 100))
      this._handleWindowScroll()

      window.benji?.gpt?.addEventListener('slotRenderEnded', event => {
        // Event Object: https://developers.google.com/publisher-tag/reference#googletag.events.slotrenderendedevent
        this.positions.forEach(position => {
          if (event?.slot?.getSlotElementId() === position.id) {
            position.onRender(event)
          }
        })
      })
      window.benji?.gpt?.addEventListener('slotRequested', event => {
        // Event Object: https://developers.google.com/publisher-tag/reference#googletag.events.slotrequestedevent
        this.positions.forEach(position => {
          if (event?.slot?.getSlotElementId() === position.id) {
            position.onRequested(event)
          }
        })
      })
    }

    _addPublicInterface() {
      window.GAM = {
        createPlacement: this.createGamPlacement.bind(this),
        destroyPlacement: this.destroyGAMPlacement.bind(this)
      }
    }
  }

  return GAM
})()
